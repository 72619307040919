<template>
  <base-section
    :style="{'background-color': background, 'padding': padding}"
  >
    <v-container :style="{'max-width': maxWidth}">
      <div v-html="description" />
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDescriptionText',
    props: {
      description: {
        type: String,
        default: 'description',

      },
      background: {
        type: String,
        default: 'auto',
      },
      maxWidth: {
        type: String,
        default: 'auto',
      },
      padding: {
        type: String,
        default: 'padding: 96px 0px',
      },
    },
  }
</script>
